import { Coverage } from "spot-types/entities/Coverage";
import { CoverageSettings, Policy, FormattedPolicy, Quote } from "@/shared/types/Quote.interface";
import { QuoteDataUtils } from "./QuoteDataUtils";
import { PetPolicy } from "spot-types/entities/PetPolicy";
import { PetPlan } from "../hooks/usePetPlans";
import { UIUtils } from "./UIUtils";
import { PublicConfig } from "../PublicConfig";
import Strings from "./Strings.constants";

export interface CoverageValue {
    name: string;
    config: CoverageSettings;
}

const coverageTypes = ["accident", "illness", "peril"] as const;
type CoverageApiType = (typeof coverageTypes)[number];

export type TransactionFeeType = "per-pet" | "per-quote";

export type PresetCoverageLevel = {
    name: string;
    config: {
        type: CoverageApiType[];
        annualLimit?: number;
        deductible?: number;
        reimbursementPercent?: number;
        rider?: "high" | "low";
    };
};

export type CoverageLevelResult = {
    [petId: string]: CoverageValue[];
};

export type CoverageMatchCriteria = "coverageType" | "annualLimit" | "annualDeductible" | "reimbursementRate" | "wellnessLevel";
export type PreventiveCoverageType = "wellness-gold" | "wellness-platinum" | "None";

export type PreventiveOption = {
    label: string;
    value: PreventiveCoverageType;
    price: number;
};

export type PreventiveConfig = {
    labels: {
        basic: string;
        advanced: string;
    };
};

export type PriceInfoData = {
    roundedPrice: number;
    discountsAmount: number;
};

export type PetPriceData = {
    preDiscountPrice: number;
    discountAmount: number;
    finalPrice: number;
};

export class CoverageUtils {
    static DEFAULT_COVERAGE_INDEX = 0;

    // format API data for UI
    static formatCustomCoverageData(
        plans: { baseCoverage: Coverage; riders: Coverage[] }[],
        isAnnualBilling: boolean
    ): {
        coverageTypes: { label: string; value: string }[];
        annualLimits: { label: string; value: string }[];
        reimbursementRates: { label: string; value: string }[];
        annualDeductibles: { label: string; value: string }[];
        preventiveCareOptions: PreventiveOption[];
    } {
        const coverageTypesMap = new Map<string, { label: string; value: string }>();
        const annualLimitsMap = new Map<string, { label: string; value: string }>();
        const reimbursementRatesMap = new Map<string, { label: string; value: string }>();
        const annualDeductiblesMap = new Map<string, { label: string; value: string }>();
        const preventiveCareOptions = new Map<string, PreventiveOption>();

        preventiveCareOptions.set("None", { label: "None", value: "None", price: 0 }); // Add "None" option for riders

        const goldOptions: PreventiveOption[] = [];
        const platinumOptions: PreventiveOption[] = [];

        plans.forEach(plan => {
            // Add coverage types handling here
            const coverageTypesArray = plan.baseCoverage.type;
            if (!!coverageTypesArray && coverageTypesArray.includes("accident") && coverageTypesArray.includes("illness")) {
                if (!coverageTypesMap.has("accidentAndIllness")) {
                    coverageTypesMap.set("accidentAndIllness", {
                        label: "Accident + Illness",
                        value: "accidentAndIllness"
                    });
                }
            } else if (coverageTypes.includes("accident")) {
                if (!coverageTypesMap.has("accidentOnly")) {
                    coverageTypesMap.set("accidentOnly", {
                        label: "Accident Only",
                        value: "accidentOnly"
                    });
                }
            }

            // Format and add annual limits
            if (plan.baseCoverage.details?.annualLimit && plan.baseCoverage?.name) {
                const { symbol = ``, value = 0 } = plan.baseCoverage.details.annualLimit;
                let label = `${symbol}${value.toLocaleString()}`;

                if (plan.baseCoverage.details.annualLimit.value === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE) {
                    label = Strings.UNLIMITED_ANNUAL_LIMIT_LABEL;
                }

                const uniqueKey = `${label}-${value.toString()}`;

                if (!annualLimitsMap.has(uniqueKey)) {
                    annualLimitsMap.set(uniqueKey, {
                        label: label,
                        value: value.toString()
                    });
                }
            }

            // Format and add reimbursement rates
            if (plan.baseCoverage.details?.reimbursementPercent && plan.baseCoverage?.name) {
                const { symbol = ``, value = 0 } = plan.baseCoverage.details.reimbursementPercent;
                const label = `${value}${symbol}`;

                const uniqueKey = `${label}-${value.toString()}`;

                if (!reimbursementRatesMap.has(uniqueKey)) {
                    reimbursementRatesMap.set(uniqueKey, {
                        label: label,
                        value: value.toString()
                    });
                }
            }

            // Format and add annual deductibles
            if (plan.baseCoverage.details?.deductible && plan.baseCoverage?.name) {
                const { symbol = ``, value = 0 } = plan.baseCoverage.details.deductible;
                const label = `${symbol}${value}`;

                const uniqueKey = `${label}-${value.toString()}`;

                if (!annualDeductiblesMap.has(uniqueKey)) {
                    annualDeductiblesMap.set(uniqueKey, {
                        label: label,
                        value: value.toString()
                    });
                }
            }

            if (plan.riders.length > 0) {
                const sortedRiders = plan.riders.filter(rider => rider.basePrice !== undefined).sort((a, b) => a.basePrice!.value - b.basePrice!.value);

                // Format and add preventive care options
                sortedRiders.forEach((rider, index) => {
                    const riderValue = index === 0 ? "wellness-gold" : "wellness-platinum";
                    const riderBasePrice = Number(rider.basePrice?.value ?? 0);

                    if (riderValue === "wellness-gold") {
                        goldOptions.push({ label: Strings.PTZ_US.PREVENTIVE_BASIC, value: "wellness-gold", price: riderBasePrice });
                    } else if (riderValue === "wellness-platinum") {
                        platinumOptions.push({ label: Strings.PTZ_US.PREVENTIVE_ADVANCED, value: "wellness-platinum", price: riderBasePrice });
                    }
                });
            }
        });

        goldOptions.forEach(option => preventiveCareOptions.set(option.value, option));
        platinumOptions.forEach(option => preventiveCareOptions.set(option.value, option));

        const sortedCoverageTypes = Array.from(coverageTypesMap.values()).sort((a, b) => {
            if (a.value === "accidentAndIllness") return -1;
            if (b.value === "accidentAndIllness") return 1;

            // Make "Accident Only" last
            if (a.value === "accidentOnly") return 1;
            if (b.value === "accidentOnly") return -1;

            return 0;
        });

        return {
            coverageTypes: sortedCoverageTypes,
            annualLimits: Array.from(annualLimitsMap.values()),
            annualDeductibles: Array.from(annualDeductiblesMap.values()).sort((a, z) => Number(z.value) - Number(a.value)), // sorts desc, z-a
            reimbursementRates: Array.from(reimbursementRatesMap.values()).sort((a, z) => Number(a.value) - Number(z.value)), // sorts asc, a-z
            preventiveCareOptions: Array.from(preventiveCareOptions.values())
        };
    }

    // format fetched plans from API into coverage presets
    static getPresetPlansFromApiData({ presetCoverageLevels, petPlans }: { presetCoverageLevels: PresetCoverageLevel[]; petPlans: PetPlan[] }): CoverageValue[] {
        const matchRider = (riders: Coverage[], category: "high" | "low"): Coverage | undefined => {
            if (!riders || riders.length === 0) return undefined;

            const sortedRiders = riders.filter(rider => rider.basePrice !== undefined).sort((a, b) => a.basePrice!.value - b.basePrice!.value);

            return category === "high" ? sortedRiders[sortedRiders.length - 1] : sortedRiders[0];
        };

        const applyConfig = (targetLevel: CoverageValue | undefined, applicableCoverages: Coverage[]) => {
            if (targetLevel) {
                const combinedCoverageSettings = applicableCoverages.reduce((previousSettings, coverage) => {
                    const currentSettings = QuoteDataUtils.extractCoveragesFromPetPolicy({ coverages: [coverage] } as unknown as PetPolicy);
                    if (currentSettings) {
                        return {
                            coverages: [...(previousSettings?.coverages || []), ...(currentSettings.coverages || [])],
                            amounts: { ...(previousSettings?.amounts || {}), ...(currentSettings.amounts || {}) },
                            extra: { ...(previousSettings?.extra || {}), ...(currentSettings.extra || {}) }
                        };
                    }
                    return previousSettings;
                }, {} as CoverageSettings);

                if (combinedCoverageSettings) {
                    targetLevel.config = combinedCoverageSettings;
                }
            }
        };

        const coveragePresets = presetCoverageLevels.flatMap(presetLevel => {
            const matches = petPlans.filter(plan => {
                const typeMatch = presetLevel.config.type.every(type => Array.isArray(plan?.baseCoverage?.type) && plan.baseCoverage.type.includes(type));
                const annualLimitMatch = presetLevel.config.annualLimit === undefined || plan.baseCoverage.details?.annualLimit?.value === presetLevel.config.annualLimit;
                const deductibleMatch = presetLevel.config.deductible === undefined || plan.baseCoverage.details?.deductible?.value === presetLevel.config.deductible;
                const reimbursementPercentMatch =
                    presetLevel.config.reimbursementPercent === undefined || plan.baseCoverage.details?.reimbursementPercent?.value === presetLevel.config.reimbursementPercent;
                const riderMatch = presetLevel.config.rider ? matchRider(plan.riders, presetLevel.config.rider) : true;
                return typeMatch && annualLimitMatch && deductibleMatch && reimbursementPercentMatch && riderMatch;
            });

            if (matches.length > 1) {
                console.error("Multiple plans matched the same preset level");
            }

            return matches.length > 0
                ? matches.slice(0, 1).map(match => {
                      // there should only be one match
                      const coverageValue: CoverageValue = {
                          name: presetLevel.name,
                          config: {}
                      };

                      const matchedRider = presetLevel.config.rider ? matchRider(match.riders, presetLevel.config.rider) : undefined;
                      const planCoverages = [match.baseCoverage, ...(matchedRider ? [matchedRider] : [])];

                      applyConfig(coverageValue, planCoverages);
                      return coverageValue;
                  })
                : [];
        });

        return coveragePresets;
    }

    static findKeysWithMultipleOptions(inputObject: Record<string, any[]>): string[] {
        // Process each [key, value] pair in the input object
        return Object.entries(inputObject).reduce((accumulatedKeys, [key, value]) => {
            // Check if the value is an array and its length is greater than 1
            if (Array.isArray(value) && value.length > 1) {
                accumulatedKeys.push(key);
            }
            return accumulatedKeys;
        }, [] as string[]);
    }

    static convertKeysToMatchCriteria(keys: string[]): CoverageMatchCriteria[] {
        const keyToCriteriaMapping: { [key: string]: CoverageMatchCriteria } = {
            coverageTypes: "coverageType",
            annualLimits: "annualLimit",
            annualDeductibles: "annualDeductible",
            reimbursementRates: "reimbursementRate",
            preventiveCareOptions: "wellnessLevel"
        };

        return keys.map(key => keyToCriteriaMapping[key]).filter(criteria => criteria !== undefined) as CoverageMatchCriteria[];
    }

    static getRiderFromCoverageType(coverageType: "wellness-gold" | "wellness-platinum", riders: Coverage[]): Coverage | undefined {
        const sortedRiders = riders.filter(rider => rider.basePrice !== undefined).sort((a, b) => a.basePrice!.value - b.basePrice!.value);

        return coverageType === "wellness-platinum" ? sortedRiders[sortedRiders.length - 1] : sortedRiders[0];
    }

    static allPetPlansHaveSameOptions(petPlans?: Record<string, PetPlan[]>): boolean {
        if (!petPlans || Object.keys(petPlans).length === 0) return false;
        if (Object.keys(petPlans).length === 1) return true;

        const referenceKey = Object.keys(petPlans)[0] as string;
        const referencePlans = petPlans[referenceKey];
        if (!referencePlans) {
            // Initial check for undefined
            return false;
        }

        return Object.keys(petPlans).every(petId => {
            const currentPlans = petPlans[petId];
            if (!currentPlans) return false; // Check if the current plans array could be undefined

            return this.isArrayOfPlansEquivalent(referencePlans, currentPlans);
        });
    }

    private static isArrayOfPlansEquivalent(plans1: PetPlan[], plans2: PetPlan[]): boolean {
        if (plans1.length !== plans2.length) return false;

        for (let i = 0; i < plans1.length; i++) {
            const plan1 = plans1[i];
            const plan2 = plans2[i];

            if (!plan1 || !plan2 || !this.isPlanEquivalent(plan1, plan2)) {
                return false;
            }
        }
        return true;
    }

    private static isPlanEquivalent(plan1: PetPlan, plan2: PetPlan): boolean {
        if (!this.isCoverageEquivalent(plan1.baseCoverage, plan2.baseCoverage)) {
            return false;
        }
        return this.areCoverageArraysEquivalent(plan1.riders, plan2.riders);
    }

    private static isCoverageEquivalent(coverage1: Coverage, coverage2: Coverage): boolean {
        return (
            coverage1.id === coverage2.id &&
            coverage1.name === coverage2.name &&
            coverage1.level === coverage2.level &&
            coverage1.type?.sort().toString() === coverage2.type?.sort().toString() &&
            coverage1.details?.deductible?.value === coverage2.details?.deductible?.value &&
            coverage1.details?.reimbursementPercent?.value === coverage2.details?.reimbursementPercent?.value
        );
    }

    private static areCoverageArraysEquivalent(coverageArray1: Coverage[], coverageArray2: Coverage[]): boolean {
        if (coverageArray1.length !== coverageArray2.length) return false;

        for (let i = 0; i < coverageArray1.length; i++) {
            const coverage1 = coverageArray1[i];
            const coverage2 = coverageArray2[i];

            if (!coverage1 || !coverage2 || !this.isCoverageEquivalent(coverage1, coverage2)) {
                return false;
            }
        }
        return true;
    }

    static allPetCoverageSettingsMatch(settingsArray: (CoverageSettings | undefined)[], matchCriteria: CoverageMatchCriteria[]): boolean {
        // Early return false if any entry is undefined
        if (settingsArray.some(setting => setting === undefined)) {
            return false;
        }

        function extractMatchCriteriaValues(coverageSettings: CoverageSettings) {
            return {
                coverageType: coverageSettings.coverages?.map(coverage => coverage.type).filter(Boolean) ?? [],
                annualLimit: coverageSettings.amounts?.annualLimit ?? -1,
                annualDeductible: coverageSettings.amounts?.annualDeductible ?? -1,
                reimbursementRate: coverageSettings.amounts?.reimbursementRate ?? -1,
                wellnessLevel: coverageSettings.coverages?.find(coverage => coverage.type?.includes("wellness"))?.preventivePrice ?? -1
            };
        }

        const extractedCriteriaValues = settingsArray.map(settings => extractMatchCriteriaValues(settings as CoverageSettings));

        return matchCriteria.every(criteria => {
            if (criteria === "coverageType") {
                // Handling for array type
                const firstArray = extractedCriteriaValues[0]?.[criteria] ?? [];
                return extractedCriteriaValues.every(values => firstArray.length === (values[criteria]?.length ?? 0) && firstArray.every(val => values[criteria]?.includes(val)));
            } else {
                // Handling for numeric types
                const firstValue = extractedCriteriaValues[0]?.[criteria] ?? -1;
                return extractedCriteriaValues.every(values => firstValue === values[criteria]);
            }
        });
    }

    static getCoverageLevelFromSettings({
        coverageSettings,
        coverageLevels,
        matchCriteria
    }: {
        coverageSettings?: CoverageSettings;
        coverageLevels: CoverageValue[];
        matchCriteria: CoverageMatchCriteria[];
    }): number {
        // Check if coverageSettings or coverageSettings.coverages is undefined
        if (!matchCriteria) {
            console.warn(`matchCriteria is required`);
            return this.DEFAULT_COVERAGE_INDEX;
        }
        if (!coverageSettings || !coverageSettings.coverages || coverageSettings.coverages.length === 0) {
            return this.DEFAULT_COVERAGE_INDEX; // Return default index if no coverage settings are defined
        }

        for (let i = 0; i < coverageLevels.length; i++) {
            const { config } = coverageLevels[i] || {};
            const { coverages = [], amounts = {} } = config || {};

            const matchesCriteria = matchCriteria.every(criteria => {
                switch (criteria) {
                    case "coverageType":
                        const coverageTypes = coverageSettings.coverages?.map(coverage => coverage.type) || [];
                        const presetCoverageTypes = coverages.map(coverage => coverage.type);
                        return JSON.stringify(coverageTypes.sort()) === JSON.stringify(presetCoverageTypes.sort());
                    case "annualLimit":
                        return coverageSettings.amounts?.annualLimit === amounts.annualLimit;
                    case "annualDeductible":
                        return coverageSettings.amounts?.annualDeductible === amounts.annualDeductible;
                    case "reimbursementRate":
                        return coverageSettings.amounts?.reimbursementRate === amounts.reimbursementRate;
                    case "wellnessLevel":
                        const wellnessLevel = coverageSettings.coverages?.find(coverage => coverage.type?.includes("wellness"))?.preventivePrice;
                        const presetWellnessLevel = coverages.find(coverage => coverage.type?.includes("wellness"))?.preventivePrice;
                        return wellnessLevel === presetWellnessLevel;
                    default:
                        return console.warn(`Unsupported coverage match criterion: ${criteria}`);
                }
            });

            if (matchesCriteria) {
                return i; // Return the index of the matching coverage level
            }
        }

        return this.DEFAULT_COVERAGE_INDEX; // Fallback to the default index if no matching preset is found
    }

    static isCustomConfig({ currentSettings, coverageLevels }: { currentSettings?: CoverageSettings; coverageLevels: CoverageValue[] }): boolean {
        if (!currentSettings || !currentSettings.coverages || currentSettings.coverages.length === 0) {
            return true; // If there are no coverages defined, it's considered a custom config by default.
        }

        const isPreset = coverageLevels.some((preset, index) => {
            const currentCoverageTypes = (currentSettings.coverages || []).map(coverage => coverage.type).sort();
            const presetCoverageTypes = (preset.config.coverages || []).map(coverage => coverage.type).sort();

            if (currentCoverageTypes.length > 0 && JSON.stringify(currentCoverageTypes) !== JSON.stringify(presetCoverageTypes)) {
                return false; // Early return if the types don't match, moving to the next preset for comparison
            }

            // If coverage types match, continue to validate amounts
            let amountsMatch = false;

            if (currentSettings.amounts && preset?.config.amounts) {
                const { annualLimit, annualDeductible, reimbursementRate } = currentSettings.amounts;
                const presetAmounts = preset.config.amounts;
                const amountsAreDefined = annualLimit !== undefined && annualDeductible !== undefined && reimbursementRate !== undefined;
                if (
                    amountsAreDefined &&
                    annualLimit === presetAmounts.annualLimit &&
                    annualDeductible === presetAmounts.annualDeductible &&
                    reimbursementRate === presetAmounts.reimbursementRate
                ) {
                    amountsMatch = true;
                }
            }

            return amountsMatch; // Both amounts and types need to match for it to be a preset; otherwise, it's considered custom.
        });

        if (!isPreset) {
            return true; // Returns true indicating it's a custom configuration
        } else {
            return false; // Returns false since a matching preset is found
        }
    }

    static getPlanDataFromCoverageSettings(plans: PetPlan[], coverageSettings: CoverageSettings, coverageMatchCriteria: CoverageMatchCriteria[]): PetPlan | null {
        const { amounts, coverages } = coverageSettings;
        const { annualDeductible, annualLimit, reimbursementRate } = amounts || {};
        const allBaseCoverageTypes = coverages?.filter(coverage => coverage.type && !coverage.type.includes("wellness")).map(coverage => coverage.type) || [];

        for (const plan of plans) {
            const { baseCoverage, riders } = plan;
            let isMatch = true;

            // Check if baseCoverage types match the ones provided in the settings
            if (!allBaseCoverageTypes.every(type => !!type && baseCoverage.type?.includes(type as "accident" | "illness"))) {
                continue;
            }

            // Check additional criteria based on coverageMatchCriteria
            for (const criterion of coverageMatchCriteria) {
                switch (criterion) {
                    case "coverageType":
                        isMatch = isMatch && allBaseCoverageTypes.every(type => baseCoverage.type?.includes(type as "accident" | "illness"));
                        break;
                    case "annualLimit":
                        isMatch = isMatch && (baseCoverage.details?.annualLimit?.value || 0) === annualLimit;
                        break;
                    case "annualDeductible":
                        isMatch = isMatch && (baseCoverage.details?.deductible?.value || 0) === annualDeductible;
                        break;
                    case "reimbursementRate":
                        isMatch = isMatch && (baseCoverage.details?.reimbursementPercent?.value || 0) === reimbursementRate;
                        break;
                    case "wellnessLevel":
                        const wellnessCoverage = coverages?.find(coverage => coverage.type?.includes("wellness"));
                        if (!wellnessCoverage) break;
                        const wellnessPrice = wellnessCoverage?.preventivePrice;
                        const pricesMatch = !!riders.find(rider => rider.basePrice?.value === wellnessPrice);
                        if (pricesMatch) {
                            isMatch = isMatch && pricesMatch;
                            break;
                        }
                        if (wellnessCoverage?.type === "wellness-gold" || wellnessCoverage?.type === "wellness-platinum") {
                            const levelsMatch = this.getRiderFromCoverageType(wellnessCoverage.type, riders);
                            isMatch = isMatch && levelsMatch !== undefined;
                        }
                        break;
                    default:
                        console.warn(`Unsupported coverage match criterion: ${criterion}`);
                }
            }

            // If all criteria are matched, return the matched plan.
            if (isMatch) {
                return plan;
            }
        }

        return null; // Return null if no match is found
    }

    static getCoverageSettingsFromPlanData(plan: PetPlan, selectedRiderLevel?: "wellness-gold" | "wellness-platinum"): CoverageSettings | null {
        // Check if base coverage name matches the selected plan name
        if (!!plan.baseCoverage.id) {
            const applicableCoverages: Coverage[] = [plan.baseCoverage];

            // If a specific rider is selected, add it to the applicable coverages
            if (selectedRiderLevel) {
                const selectedRider = this.getRiderFromCoverageType(selectedRiderLevel, plan.riders);
                if (selectedRider) {
                    applicableCoverages.push(selectedRider);
                }
            }

            // Combine the coverages into a single configuration
            const combinedCoverageConfig = QuoteDataUtils.extractCoveragesFromPetPolicy({ coverages: applicableCoverages } as unknown as PetPolicy);
            if (combinedCoverageConfig) {
                return combinedCoverageConfig;
            }
        }

        return null; // Return null if no match is found
    }

    static calculatePriceData({
        coverageSettings,
        policies,
        isMultiPetLinked,
        activePetId,
        petPlans,
        coverageValuesToMatch,
        isAnnualBilling,
        transactionFee,
        feeType
    }: {
        coverageSettings: CoverageSettings;
        policies?: Policy[];
        isMultiPetLinked?: boolean;
        activePetId?: string;
        petPlans: { [petId: string]: PetPlan[] };
        coverageValuesToMatch: CoverageMatchCriteria[];
        isAnnualBilling: boolean;
        transactionFee: number;
        feeType: TransactionFeeType;
    }): {
        preDiscountPrice: number;
        discountAmount: number;
        finalPrice: number;
        perPetPriceData: { [petId: string]: PetPriceData };
    } {
        let preDiscountPrice = 0;
        let discountAmount = 0;
        let finalPrice = 0;
        const perPetPriceData: { [petId: string]: PetPriceData } = {};

        if (!activePetId) {
            console.error("Active pet ID is required.");
            throw new Error("Active pet ID is required");
        }

        // Ensure only policies with defined id are processed
        const validPolicies = policies?.filter(policy => policy.id !== undefined) || [];

        validPolicies.forEach(policy => {
            const policyId = policy.id!;
            const currentCoverageSettings = isMultiPetLinked || policyId === activePetId ? coverageSettings : policy.coverageSettings;
            if (!currentCoverageSettings) {
                return; // Skip this pet
            }

            if (!petPlans[policyId]) {
                return;
            }

            // Define petPlans for the current petId or return early if undefined
            const currentPetPlans = petPlans[policyId];

            if (!currentPetPlans) {
                return; // Skip calculation if no plans exist for the current pet
            }

            const plan = this.getPlanDataFromCoverageSettings(currentPetPlans, currentCoverageSettings, coverageValuesToMatch);
            if (!plan) {
                return; // Skip further calculation if no plan matches
            }

            const baseSubtotal = plan.baseCoverage.basePrice?.value ?? 0;
            const taxAmount = plan.baseCoverage.taxAmount?.value ?? 0;
            const petPreDiscountPrice = baseSubtotal + taxAmount;
            preDiscountPrice += petPreDiscountPrice;
            const discountTotal = baseSubtotal * (policy.discountTotalPercentage ?? 0);
            discountAmount += discountTotal;
            const baseSubtotalAdjusted = baseSubtotal - discountTotal + taxAmount;
            const petFinalPrice = baseSubtotalAdjusted;
            finalPrice += petFinalPrice;

            // Initialize price data for this pet
            perPetPriceData[policyId] = {
                preDiscountPrice: petPreDiscountPrice,
                discountAmount: discountTotal,
                finalPrice: petFinalPrice
            };

            // If there is selected wellness coverage, then calculate rider price
            const wellnessSelected = currentCoverageSettings.coverages?.find(coverage => coverage.preventivePrice !== undefined);

            if (wellnessSelected) {
                plan.riders.forEach(rider => {
                    if (wellnessSelected.preventivePrice === rider?.basePrice?.value) {
                        const riderSubtotal = (rider.basePrice?.value ?? 0) - (rider.discountAmount?.value ?? 0) + (rider.taxAmount?.value ?? 0);
                        preDiscountPrice += riderSubtotal;
                        finalPrice += riderSubtotal;

                        perPetPriceData[policyId]!.preDiscountPrice += rider.basePrice?.value ?? 0;
                        perPetPriceData[policyId]!.finalPrice += riderSubtotal;
                    }
                });
            }

            // Add per-pet transaction fee if applicable
            if (feeType === "per-pet" && !isAnnualBilling) {
                const perPetFee = transactionFee / validPolicies.length;
                preDiscountPrice += perPetFee;
                finalPrice += perPetFee;
                perPetPriceData[policyId].preDiscountPrice += perPetFee;
                perPetPriceData[policyId].finalPrice += perPetFee;
            }
        });

        // Add per-quote transaction fee if applicable
        if (feeType === "per-quote" && !isAnnualBilling) {
            preDiscountPrice += transactionFee;
            finalPrice += transactionFee;
        }

        const annualMultiplier = isAnnualBilling ? 12 : 1;

        Object.keys(perPetPriceData).forEach(petId => {
            perPetPriceData[petId] = {
                preDiscountPrice: Number((perPetPriceData[petId]!.preDiscountPrice * annualMultiplier).toFixed(2)),
                discountAmount: Number((perPetPriceData[petId]!.discountAmount * annualMultiplier).toFixed(2)),
                finalPrice: Number((perPetPriceData[petId]!.finalPrice * annualMultiplier).toFixed(2))
            };
        });

        return {
            preDiscountPrice: Number((preDiscountPrice * annualMultiplier).toFixed(2)),
            discountAmount: Number((discountAmount * annualMultiplier).toFixed(2)),
            finalPrice: Number((finalPrice * annualMultiplier).toFixed(2)),
            perPetPriceData
        };
    }

    static calculatePetPremium(policy: Policy, petFee: number) {
        const basePrice = policy.basePrice?.value ?? 0;
        const discount = policy.discountAmount?.value ?? 0;
        const fee = petFee;

        return basePrice - discount + fee;
    }

    // Calculates total based on policy final prices + transaction fee:
    static calculatePolicyTotal(policies: Policy[] = [], transactionFee = 0): number {
        return (
            (policies.reduce((accumulator, policy) => {
                return accumulator + (policy.finalPrice?.value ?? 0);
            }, 0) ?? 0) + transactionFee
        );
    }

    // Prepares formattedPolicies with all necessary values (formatted and unformatted)
    static formatPolicyDetails = (policy: Policy): FormattedPolicy => {
        const { age, coverageSettings, discountAmount } = policy;
        const petAge = `${age === 0 ? "Under 1" : age} year${age && age > 1 ? "s" : ""} old`;
        const annualDeductible = `$${UIUtils.formatNumber(coverageSettings?.amounts?.annualDeductible ?? 0, 2)}`;
        const discountsAmount = UIUtils.formatNumber(discountAmount?.value ?? 0, 2);
        const reimbursementRate = `${coverageSettings?.amounts?.reimbursementRate?.toLocaleString()}%` ?? "NA";
        const annualLimit =
            coverageSettings?.amounts?.annualLimit === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE
                ? Strings.UNLIMITED_ANNUAL_LIMIT_LABEL
                : `$${UIUtils.formatNumber(coverageSettings?.amounts?.annualLimit ?? 0, 2)}`;

        // Initialize coverage dates with defaults
        let accidentCreateDate = "";
        let accidentCoverageDate = "";
        let illnessCoverageDate = "";
        let perilIllnessCoverageDate = "";
        let wellnessCoverageDate = "";
        let wellnessCoveragePrice = 0;

        // Iterate over coverages and format dates based on type
        policy?.coverageSettings?.coverages?.forEach(coverage => {
            switch (coverage.type) {
                case "accident":
                    accidentCreateDate = coverage.createDate ?? "";
                    accidentCoverageDate = coverage.startDate ?? "";
                    break;
                case "illness":
                    illnessCoverageDate = coverage.startDate ?? "";
                    break;
                case "peril":
                    perilIllnessCoverageDate = coverage.startDate ?? "";
                    break;
                case "wellness-gold":
                case "wellness-platinum":
                    wellnessCoverageDate = coverage.startDate ?? "";
                    wellnessCoveragePrice = coverage.preventivePrice ?? 0;
                    break;
            }
        });

        // Return the new policy object including both unformatted and formatted values
        return {
            ...policy, // This spreads all the existing policy properties that don't need formatting
            petAge,
            discountsAmount,
            annualLimit,
            annualDeductible,
            reimbursementRate,
            accidentCreateDate,
            accidentCoverageDate,
            illnessCoverageDate,
            perilIllnessCoverageDate,
            wellnessCoverageDate,
            wellnessCoveragePrice,
            hasWellnessCoverage: wellnessCoveragePrice > 0 && wellnessCoverageDate !== "",
            isAccidentOnly: !illnessCoverageDate
        };
    };

    // Takes a policy purchase date as a string (ex: YYYY-MM-DD) and returns a formatted date
    static calculateCoverageDate = (dateStr: string, daysToAdd: number = 0, format: string = "default") => {
        const date = new Date(dateStr + "T00:00:00");
        date.setDate(date.getDate() + daysToAdd);

        if (format === "relative") {
            const today = new Date();
            const diffInTime = date.getTime() - today.getTime();
            const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

            if (diffInDays === 1) {
                return "tomorrow";
            } else if (diffInDays <= 7) {
                return `in ${diffInDays} days`;
            } else {
                /* 
                    Adjusted Week Calculation: After the first 7 days, each new 7-day period starts the week count.
                    Days 8–14 count as "In 1 week".
                    Days 15–21 count as "In 2 weeks".And so on. 
                */
                const weekCount = Math.ceil((diffInDays - 7) / 7); // Adjust week count to start after 7 days;
                return `in ${weekCount} week${weekCount > 1 ? "s" : ""}`;
            }
        } else if (format === "long") {
            const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric", year: "numeric" };
            return date.toLocaleDateString("en-US", options); // Example output: "Sept. 10, 2024"
        } else {
            // Default format as "M/D/YY" or "MM/DD/YY"
            const year = date.getFullYear().toString().slice(-2);
            const month = (date.getMonth() + 1).toString();
            const day = date.getDate().toString();
            return `${month}/${day}/${year}`;
        }
    };

    static getPriceInfoData = ({
        value,
        petIdToShow,
        includeTransactionFee = true,
        feeType = "per-quote"
    }: {
        value?: Quote;
        includeTransactionFee?: boolean;
        petIdToShow?: string;
        feeType?: TransactionFeeType;
    }) => {
        if (!value) return { roundedPrice: 0, discountsAmount: 0 };
        let totalPrice = 0;
        let discountsAmount = 0;
        const policies = value?.policies ?? [];
        let transactionFee = includeTransactionFee ? (value?.transactionFee?.value ?? 0) : 0;

        const totalPets = policies.length;

        if (!!transactionFee && feeType === "per-pet" && !!petIdToShow) {
            transactionFee = transactionFee / totalPets;
        }

        if (petIdToShow) {
            const currentPet = policies.filter(policy => policy.id === petIdToShow);
            if (currentPet.length) {
                totalPrice = CoverageUtils.calculatePolicyTotal(currentPet, transactionFee);
                discountsAmount = currentPet[0]?.discountAmount?.value ?? 0;
            }
        } else {
            totalPrice = CoverageUtils.calculatePolicyTotal(policies, transactionFee);
            discountsAmount = policies.reduce((acc, policy) => acc + (policy.discountAmount?.value ?? 0), 0);
        }
        const roundedPrice = Number(totalPrice.toFixed(2));
        return { roundedPrice, discountsAmount };
    };
}
